import React from "react"

import PageNewsDetail from "components/PageNewsDetail/PageNewsDetail"
import Layout from "components/Layout/Layout"

import useChangeLanguage from "hooks/useChangeLanguage"

const NewsDetail = ({ pageContext: { slug, article, articlesList, lang } }) => {
  useChangeLanguage(lang)
  return (
    <Layout>
      <PageNewsDetail
        article={article}
        articlesList={articlesList}
        slug={slug}
      />
    </Layout>
  )
}

export default NewsDetail
